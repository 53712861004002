import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const QueridaSensatez = ({ data }) => (
  <Layout>
    <SEO title="Querida Sensatez" />
    <div className="podcast">
      <div
        className="podcast-grid"
        data-sal="slide-bottom"
        data-sal-delay="600"
        data-sal-easing="ease"
      >
        {data.allWordpressPage.edges.map(({ node }) => (
          <Link
            key={node.id}
            className="podcast-grid__link"
            to={`/${node.slug}`}
          >
            <div className="podcast-grid__post2">
              <div className="podcast-grid__postAbout">
                <h3>{node.title}</h3>
                <div>
                  <p>{node.date}</p>
                </div>
              </div>
              <img
                className="podcast-grid__postCover"
                alt="Podcast Cover"
                src={node.acf.imagen_portada.source_url}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  </Layout>
)

export default QueridaSensatez

export const pageQuery = graphql`
  query {
    allWordpressPage(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          status
          date(formatString: "DD/MM/YYYY")
          acf {
            imagen_portada {
              source_url
            }
            querida_anterior
          }
        }
      }
    }
  }
`
